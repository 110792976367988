@font-face {
	font-family: 'Karla';
    font-style: normal;
    font-weight: normal;
    src: url('./Karla-Regular.woff') format('woff');
}
    

@font-face {
	font-family: 'Karla';
	font-style: italic;
	font-weight: normal;
	src: url('./Karla-Italic.woff') format('woff');
}


@font-face {
	font-family: 'Karla';
	font-style: normal;
	font-weight: bold;
	src: url('./Karla-Bold.woff') format('woff');
}


@font-face {
	font-family: 'Karla';
	font-style: italic;
	font-weight: bold;
	src: url('./Karla-BoldItalic.woff') format('woff');
}